<template>
  <div class="tree">
    <!-- :default-expanded-keys="[lastPlayItemId]" -->
    <el-tree :data="data" node-key="contentId" default-expand-all @node-click="handleNodeClick"
      @node-expand="nodeExpandClick" :props="defaultProps">
      <!-- data -->
      <div class="custom-tree-node" slot-scope="{ data }">
        <div class="currentStudy" v-show="data.contentId === +lastPlayItemId && showTootip" @click="handleClick($event)">
          <span class="tipText"> 之前学到这里啦！ </span>
          <span>
            <svg-icon icon-class="tootip_close"></svg-icon>
          </span>
        </div>
        <p :title="data.stageName || data.contentName" class="label1">
          <!-- 内容类型 -->
          <!-- :class="ConstData.taskType[data.type].type" -->
          <span class="type" v-if="data.type != 1 && data.type != 2 && data.type">{{ ConstData.taskType[data.type].text
          }}</span>
          <!-- :class="ConstData.courseType[data.contentType].type" -->
          <!-- 素材类型 -->
          <span class="type" v-if="data.contentType">{{ ConstData.courseType[data.contentType].text }}</span>
          <span class="name">{{
            data.stageName || data.contentName
          }}</span>
        </p>
        <!-- {{ data }} -->
        <!-- 右边信息 -->
        <div class="rightInfo rightWidth360">
          <div class="lock-icon" v-if="data.isLocked">
            <svg-icon icon-class="lock"></svg-icon>
          </div>
          <template v-else>
            <!-- 素材信息 -->
            <template v-if="[1, 2, 3, 4].includes(data.contentType)">
              <!-- 时长 视频音频 -->
              <span class="timeVal" :class="{ isHidden: !data.isLocked }"
                v-if="[1, 2].includes(data.contentType) && data.duration">时长 · {{ minutes(data.duration) }}分{{
                  seconds(data.duration)
                }}秒</span>
              <!-- 学习进度百分比 -->
              <span class="highLight" :class="{ isHidden: !data.isLocked }">
                <!-- eslint-disable -->
                {{ data.studyInfo?.learnRate === 100 ? '已完成' : data.studyInfo?.learnRate || '0'}}{{ ( data.studyInfo?.learnRate || 0) < 100 ? '%' : '' }}
              </span>
              <el-button class="studyBtn" v-if="!data.isLocked" type="primary">进入学习</el-button>
            </template>
            <!-- 考试 3考试通过/2未通过/1待阅卷/0未交卷 -->
            <template v-if="data.type === 3">
              <span class="typeClassName" :class="{ typeClassName1: [0,3].includes(data.contentStatus) }">
                {{ data.contentStatus === 1 ? '待阅卷' : data.contentStatus === 2 ? '未通过' : data.contentStatus === 3 ? '考试通过' : '进入考试' }}
              </span>
            </template>
            <!--  -->
            <!-- 作业  3通过/2未通过/1待审批/0未提交 -->
            <span v-if="data.type === 5" class="typeClassName" :class="{ typeClassName1: [3].includes(data.contentStatus) }">
              {{ data.contentStatus === 1 ? '待审批' : data.contentStatus === 2 ? '未通过' : data.contentStatus === 3 ? '通过' : '未提交' }}
            </span>
            <!-- 线下实训  2已结束/1进行中/0未开始-->
            <span v-if="data.type === 8" class="typeClassName" :class="{ typeClassName1: data.contentStatus }">
              {{ data.contentStatus === 2 ? '已结束' : data.contentStatus === 0 ? '未开始' : ''}}
            </span>
            <!-- 直播相关 -->
            <template v-if="data.type === 4">
              <p class="rightWidth360-time">
                开始时间·{{ data.contentStartTime }}
              </p>
              <template v-if="data.contentStatus === 2">
                <span class="liveStatus">·已结束</span>
              </template>
              <!--  只有直播中、看回放&&开启回放  显示-->
              <!-- <template v-if="[1, 2].includes(data.contentStatus)"> -->
              <template v-if="data.contentStatus === 1 ||
                (data.contentStatus === 2 && data.isReplay === 1)
                ">
                <template v-if="data.contentStatus === 1">
                  <img class="rightWidth360-img" :src="liveCourseLive" alt="" />
                </template>
                <template v-if="data.isReplay === 1 && !!data.replayUrl">
                  <img class="rightWidth360-img" :src="liveCouresBack" alt="" />
                </template>
                <p class="rightWidth360-btn">
                  {{
                    data.contentStatus === 1
                    ? "进入直播"
                    : data.isReplay === 1 && !!data.replayUrl
                      ? "看回放"
                      : ""
                  }}
                </p>
              </template>
            </template>
            <!-- 问卷/练习 -->
            <template v-if="[6,7].includes(data.type)">
              <span class="typeClassName" :class="{ typeClassName1: data.submitStatus }">{{ data.submitStatus ? "已提交" :
                "未提交" }}</span>
            </template>
          </template>


        </div>
      </div>
    </el-tree>
  </div>
</template>

<script>
import liveCouresBack from "@/assets/images/live/live_coures_back.png";
import liveCourseLive from "@/assets/images/live/live_course_live.png";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "contentName",
      },
      showTootip: true,
      lastPlayItemId: "",
      liveCourseLive,
      liveCouresBack,
    };
  },
  created() {
    this.quickLearn();
  },
  /* eslint-disable */
  methods: {
    handleClick(e) {
      e.stopPropagation();
      this.showTootip = false;
    },
    quickLearn() {
      const params = {
        taskId: this.$route.query.id,
        // taskType: 2,
      };
      console.log(params);
      return this.$api.learn.quickLearn({ params }).then((res) => {
        if (res.data) {
          this.lastPlayItemId = res.data.lastPlayItemId || "";
        }
      });
    },
    minutes(duration) {
      let minutes = Math.floor(duration / 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return minutes;
    },
    seconds(duration) {
      let seconds = parseInt(duration % 60);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return seconds;
    },

    handleNodeClick(data) {
      this.$emit("clickNode", data);
    },
    nodeExpandClick(data, node) {
      // this.updateChildren(node)
    },
    updateChildren(list) {
      if (list.childNodes.length > 0) {
        list.childNodes.forEach((item) => {
          item.expanded = true;
          this.updateChildren(item);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.studyBtn {
  height: 32px;
  border-radius: 16px;
  padding: 0 16px;
  margin-left: auto;
}

.typeClassName {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aeb7c7;
  line-height: 14px;
  text-align: right;
  width: 100%;
  margin-right: 20px;
}

.typeClassName1 {
  color: $WDPrimaryColor;
}

.rightWidth360 {
  width: 400px !important;
  display: flex;
  align-items: center !important;
  justify-content: flex-end !important;

  .lock-icon {
    margin-left: 10px !important;
  }
}

.rightWidth360-img {
  margin-left: 14px;
  width: 23px;
  height: 22px;
}

.rightWidth360-btn {
  padding-left: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #316fff;
}
</style>

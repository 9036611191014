<template>
  <el-dialog class="offlineTrainingDialog" :visible.sync="dialogVisible.show">
    <div class="signIn">
      <div class="top">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/timerIcon.png" alt="">
        <span class="time">{{ offlineTrainUserInfo?.signInTime }}</span>
      </div>
      <div class="content">
        <div class="contentL">
          <div class="left">
            <p class="time">{{ offlineTrainInfo?.signInStartTime }}</p>
            <p class="time">{{ offlineTrainInfo?.signInEndTime }}</p>
          </div>
          <div class="middle">
            <p class="line"></p>
          </div>
          <div class="right">
            <div class="signInStart">
              <p class="headImg">签到</p>
              <div class="signInStartR">
                <p class="name">{{ offlineTrainInfo?.name }}</p>
                <p class="text">考勤负责人：{{ offlineTrainInfo?.signInManager }}</p>
              </div>
            </div>
            <div class="signInEnd">
              <!-- <img src="../../../assets/images/userL.png" class="avatar" alt=""> -->
              <div class="signInEndR">
                <p class="name">{{ offlineTrainInfo?.teacherName }}</p>
                <p class="address">地点：{{ offlineTrainInfo?.address }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="contentR"
          :class="offlineTrainUserInfo?.signInStatusText === '正常' ? 'signInStatus1' : offlineTrainUserInfo?.signInStatusText === '迟到' ? 'signInStatus2' : 'signInStatus3'">
          <p>{{ offlineTrainUserInfo?.signInStatusText }}</p>
        </div>
      </div>
    </div>
    <div class="signOut">
      <div class="top">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/timerIcon.png" alt="">
        <span class="time">{{ offlineTrainUserInfo?.signOutTime }}</span>
      </div>
      <div class="content">
        <div class="contentL">
          <div class="left">
            <p class="time">{{ offlineTrainInfo?.signOutStartTime }}</p>
            <p class="time">{{ offlineTrainInfo?.signOutEndTime }}</p>
          </div>
          <div class="middle">
            <p class="line"></p>
          </div>
          <div class="right">
            <div class="signInStart">
              <p class="headImg">签退</p>
              <div class="signInStartR">
                <p class="name">{{ offlineTrainInfo?.name }}</p>
                <p class="text">考勤负责人：{{ offlineTrainInfo?.signInManager }}</p>
              </div>
            </div>
            <div class="signInEnd">
              <!-- <img src="../../../assets/images/userL.png" class="avatar" alt=""> -->
              <div class="signInEndR">
                <p class="name">{{ offlineTrainInfo?.teacherName }}</p>
                <p class="address">地点：{{ offlineTrainInfo?.address }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="contentR"
          :class="offlineTrainUserInfo?.signOutStatusText === '正常' ? 'signInStatus1' : offlineTrainUserInfo?.signInStatusText === '早退' ? 'signInStatus4' : 'signInStatus3'">
          <p>{{ offlineTrainUserInfo?.signOutStatusText }}</p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Object,
      default: () => {
        return {
          show: false
        }
      }
    },
    offlineTrainInfo: {
      type: Object,
      default: () => { return {} }
    },
  },
  data() {
    return {
      offlineTrainUserInfo: null,
      currentTime: new Date()
    }
  },
  created() {
    console.log('offlineTrainInfo', this.offlineTrainInfo);
    if (this.offlineTrainInfo.signManagerUsers) {
      this.offlineTrainInfo.signInManager = this.offlineTrainInfo.signManagerUsers.map((item) => {
        return item.realname || item.name;
      }).join(',')
    }
    console.log('this.offlineTrainInfo.signInManager', this.offlineTrainInfo.signInManager);


    this.findSelfByTrainId()
  },
  methods: {
    findSelfByTrainId() {
      const params = {
        trainId: this.offlineTrainInfo.id
      }
      this.$api.learn.findSelfByTrainId({ params }).then(res => {
        this.offlineTrainUserInfo = res.data || null;
        if (this.offlineTrainUserInfo) {
          const signInStatus = this.offlineTrainUserInfo.signInStatus; // 签到状态 0 缺勤 1 签到
          const signInStartTime = new Date(this.offlineTrainInfo.signInStartTime); // 签到开始时间
          const signInEndTime = new Date(this.offlineTrainInfo.signInEndTime); // 签到结束时间
          const signInTime = this.offlineTrainUserInfo.signInTime && new Date(this.offlineTrainUserInfo.signInTime) || ''; // 签到时间
          const signOutTime = this.offlineTrainUserInfo.signOutTime && new Date(this.offlineTrainUserInfo.signOutTime) || '';// 签退时间
          const signOutStatus = this.offlineTrainUserInfo.signOutStatus;//签退状态 0 缺勤 1 签到
          const signOutStartTime = new Date(this.offlineTrainInfo.signOutStartTime); //签退开始时间
          const signOutEndTime = new Date(this.offlineTrainInfo.signOutEndTime); //签退结束时间
          console.log('this.offlineTrainUserInfo.signInStatus',this.offlineTrainUserInfo.signInStatus);
          console.log('signInTime > signInEndTime && signInStatus === 1',signInTime > signInEndTime && signInStatus === 1);
          
          if (this.currentTime <= signInEndTime && !signInStatus) {
            /*** 当前时间 <= 签到结束 && 未签到*/
            this.offlineTrainUserInfo.signInStatusText = '未签到';
          } else if (signInTime && signInTime > signInEndTime && signInStatus) {
            // 签到时间 && 签到时间 > 签到结束时间 && 签到
            this.offlineTrainUserInfo.signInStatusText = '迟到';
          } else if (signInStatus === 1 && (signInTime >= signInStartTime || signInTime <= signInEndTime)) {
            this.offlineTrainUserInfo.signInStatusText = '正常';
          } else if (signInStatus === 0) {
            this.offlineTrainUserInfo.signInStatusText = '缺勤';
          }
          
          if (this.currentTime <= signOutEndTime && !signOutStatus) {
            // 当前时间 <= 签退结束 && 未签退
            this.offlineTrainUserInfo.signOutStatusText = '未签退';
          } else if (signOutTime && signOutTime < signOutStartTime && signOutStatus) {
            // 签退时间 && 签退时间 < 签退开始时间 && 签退
            this.offlineTrainUserInfo.signOutStatusText = '早退';
          } else if (signOutStatus === 1 && (signOutTime >= signOutStartTime || signOutTime <= signOutEndTime)) {
            this.offlineTrainUserInfo.signOutStatusText = '正常';
          } else if (signOutStatus === 0) {
            this.offlineTrainUserInfo.signOutStatusText = '缺勤';
          }
          console.log('this.offlineTrainUserInfo.signInStatusText',this.offlineTrainUserInfo.signInStatusText);

        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    border-radius: 16px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__headerbtn {
    top: 15px;
    right: 15px;
  }

  .el-dialog__close {
    color: #333;
    font-weight: 600;
  }

  .el-dialog__body {
    padding: 40px;
    padding-bottom: 38px;
  }
}

.signIn,
.signOut {
  margin-bottom: 8px;

  .top {
    height: 36px;
    background: #EEF4FF;
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    align-items: center;

    img {
      width: 17px;
      margin-right: 18px;
    }

    .time {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }

  .content {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #DFE5F1;
    padding: 17px 16px 17px 40px;
    display: flex;
    justify-content: space-between;

    .contentL {
      display: flex;
    }

    .contentR {
      // width: 32px;
      height: 18px;
      padding: 0 4px;
      border-radius: 2px;
      margin-left: 16px;
      text-align: center;
      line-height: 18px;

      &.signInStatus1 {
        background-color: #45B525;
      }

      &.signInStatus2 {
        background-color: #FF5454;
      }

      &.signInStatus3 {
        background-color: #999999;
      }

      &.signInStatus4 {
        background-color: #FFD154;
      }

      p {
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .left {
      .time {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
      }

      .time:first-child {
        margin-bottom: 43px;
      }
    }

    .middle {
      margin-left: 15px;
      margin-right: 24px;

      .line {
        width: 1px;
        height: 100%;
        border-left: 1px dashed #DFE5F1;
        position: relative;

        &::after,
        &::before {
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          background: #2C56FF;
          border-radius: 50%;
        }

        &::after {
          left: -3px;
          top: 4px;
        }

        &::before {
          left: -3px;
          bottom: 25px;
        }
      }
    }

    .right {
      .signInStart {
        margin-bottom: 26px;
      }

      .signInStart,
      .signInEnd {
        display: flex;

        .name {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 17px;
          margin-bottom: 2px;
        }

        .address,
        .text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }

        .headImg {
          background: #2C56FF;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 32px;
        }

        .avatar,
        .headImg {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 16px;
        }
      }
    }
  }
}</style>
export const goLiveHome = (vm, item) => {
  // contentStatus = 直播状态, 0：未开始 1：直播中 2：正常结束
  if (item.contentStatus === 0) {
    vm.$toast("直播未开始");
    return;
  }
  // 判断当前是直播或者直播间 且直播正常结束 不允许观看回放 情况下 提示直播结束并阻断页面跳转
  if (item.contentStatus === 2 && item.isReplay === 0) {
    vm.$toast("直播已结束");
    return;
  }
  // 直播回放
  if (item.isReplay === 1 && item.replayUrl) {
    window.open(item.replayUrl, "_blank");
    return;
  }
  vm.$api.live.getRoomParam({
    live_id: item.contentId,
  }).then((res) => {
    if (res.code === 0) {
      let data = res.data;
      if (!data.room_id) {
        vm.$toast("直播间不存在");
        return;
      }
      let url = `https://class.qcloudclass.com/latest/class.html?userid=${data.live_user_id}&token=${data.token}&classid=${data.room_id}`;
      window.location.href = url;
    } else {
      vm.$toast(res.data.message);
    }
  }
  );

  // vm.$api.live
  //   .saveHeaderInfo({
  //     contentId: item.contentId,
  //     contentStatus: item.contentStatus,
  //     token: localStorage.getItem("token"),
  //     replayUrl: item.replayUrl,
  //   })
  //   .then((unlimite) => {
  //     liveHomeLink(unlimite);
  //   });
  // const liveHomeLink = (unlimite) => {
  //   let hostname = window.location.hostname;
  //   let port = window.location.port;
  //   let isIP = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
  //   let API = "";
  //   let locationHref = window.location.href;
  //   if (locationHref.includes("testing")) {
  //     API = "https://jj-live-pc.testing3.wdeduc.com";
  //   } else if (locationHref.includes("fat")) {
  //     API = "https://jj-live-pc.fat.wdeduc.com";
  //   } else if (locationHref.includes("uat")) {
  //     API = "https://jj-live-pc.uat.wdeduc.com";
  //   } else {
  //     //to do
  //     API = "https://jj-live-pc.wdeduc.com";
  //   }
  //   if (isIP.test(hostname) || hostname.includes("dev") || !!port) {
  //     API = "https://jj-live-pc.testing3.svc.k8s.bjo.wdcloud.cc:1025";
  //   }
  //   window.open(`${API}/liveHome?unlimitedId=${unlimite.data.unlimited_id}`,'_blank')
  // };
};
